import type { Game } from '../api/gameLaunch';
import useBlankAntGameList from './useBlackAntGameList';
import useEvoGameList from './useEvoGameList';
import usePheonixClubGameList from './usePhoenixClubGameList';
import useSaGameList from './useSaGameList';
import useSimplePlayGameList from './useSimplePlayGameList';
/*
const useGameList = () => {
  const { data, error } = useSWR('/game-launch/games', fetcher);

  return {
    games: (data || []) as GameSiteLobby[],
    isLoading: !error && !data,
    isError: error,
  };
};
*/

type GameMap = { [key in string]: Game[] };

const useGameList = () => {
  const { games: evoGames } = useEvoGameList();
  const { games: simplePlayGames } = useSimplePlayGameList();
  const { games: saGames } = useSaGameList();
  const { games: blackAntGames } = useBlankAntGameList();
  const { games: pcGames } = usePheonixClubGameList();

  const games = [
    ...evoGames,
    ...simplePlayGames,
    ...saGames,
    ...blackAntGames,
    ...pcGames,
  ];

  const gamesByType = games.reduce((acc: GameMap, curr) => {
    const category = curr.gameType;
    if (acc[category] === undefined) {
      acc[category] = [];
    }

    acc[category].push(curr);
    return acc;
  }, {});

  return {
    games,
    gamesByType,
  };
};
export default useGameList;
