import { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import type { Game } from '../api/gameLaunch';

const usePheonixClubGameList = () => {
  const games: Game[] = [
    {
      id: 'PcCockFight',
      gameSite: GameSite.PC,
      gameType: 'cockFight',
      name: 'Cock Fight',
      imgUrl: 'pc/cockfight.jpg',
      launchCode: null,
      betCode: null,
    },
  ];
  return {
    games,
  };
};

export default usePheonixClubGameList;
