import { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import type { Game } from '../api/gameLaunch';

const useEvoGameList = () => {
  const games: Game[] = [
    {
      id: 'roulette',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_roulette',
      imgUrl: 'evo/roulette.jpg',
      launchCode: 'roulette',
      betCode: null,
    },
    {
      id: 'baccarat',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_baccarat',
      imgUrl: 'evo/bac.jpg',
      launchCode: 'baccarat',
      betCode: null,
    },
    {
      id: 'blackjack',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_blackjack',
      imgUrl: 'evo/blackjack.jpg',
      launchCode: 'blackjack',
      betCode: null,
    },
    {
      id: 'craps',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_craps',
      imgUrl: 'evo/craps.jpg',
      launchCode: 'game_shows',
      betCode: null,
    },
    {
      id: 'dragontiger',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_dragontiger',
      imgUrl: 'evo/dragon-tiger.jpg',
      launchCode: 'dragontiger',
      betCode: null,
    },
    {
      id: 'sicbo',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_sicbo',
      imgUrl: 'evo/sicbo.jpg',
      launchCode: 'sicbo',
      betCode: null,
    },
    {
      id: 'moneywheel',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'EVOneywheel',
      imgUrl: 'evo/moneywheel.jpg',
      launchCode: 'game_shows',
      betCode: null,
    },
    {
      id: 'topcard',
      gameSite: GameSite.EVO,
      gameType: 'live',
      name: 'evo_topcard',
      imgUrl: 'evo/topcard.jpg',
      launchCode: 'game_shows',
      betCode: null,
    },
  ];
  return {
    games,
  };
};

export default useEvoGameList;
