import { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import type { Game } from '../api/gameLaunch';

const useSaGameList = () => {
  const games: Game[] = [
    {
      id: 'SaRoulette',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'roulette',
      imgUrl: 'sa/roulette.jpg',
      launchCode: '533',
      betCode: '533',
      rtp: 0.96,
    },
    {
      id: 'SaBaccarat',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'baccarat',
      imgUrl: 'sa/baccarat.jpg',
      launchCode: '871',
      betCode: '871',
      rtp: 0.96,
    },
    {
      id: 'SaBlackjack',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'blackjack',
      imgUrl: 'sa/blackjack.jpg',
      launchCode: '536',
      betCode: '536',
      rtp: 0.96,
    },
    /*
    {
      id: 'SaSpeedBaccarat',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: '\u6781\u901f\u767e\u5bb6\u4e50',
      imgUrl: 'sa/baccarat.jpg',
      launchCode: '878',
      betCode: '878',
      rtp: 0.96,
    },
    
    {
      id: 'SaXocDia',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: '\u8272\u789f',
      imgUrl: 'sa/cn/xocdia.jpg',
      launchCode: '531',
      betCode: '531',
      rtp: 0.96,
    },
    */
    {
      id: 'SaSicBo',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'sicbo',
      imgUrl: 'sa/sicbo.jpg',
      launchCode: '532',
      betCode: '532',
      rtp: 0.96,
    },

    {
      id: 'SaDragonTiger',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'dragon_tiger',
      imgUrl: 'sa/dragon-tiger.jpg',
      launchCode: '534',
      betCode: '534',
      rtp: 0.96,
    },
    {
      id: 'SaPokDeng',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'pokdeng',
      imgUrl: 'sa/pok-deng.jpg',
      launchCode: '535',
      betCode: '535',
      rtp: 0.96,
    },
    {
      id: 'SaAndarBahar',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'andar_bahar',
      imgUrl: 'sa/andar-bahar.jpg',
      launchCode: '537',
      betCode: '537',
      rtp: 0.96,
    },
    {
      id: 'SaTeenPatti2020',
      gameSite: GameSite.SA,
      gameType: 'live',
      name: 'teen_patti_20_20',
      imgUrl: 'sa/teen-patti-2020.jpg',
      launchCode: '538',
      betCode: '5318',
      rtp: 0.96,
    },
  ];

  return {
    games,
  };
};

export default useSaGameList;
