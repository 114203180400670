import { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import type { Game } from '../api/gameLaunch';

const useBlankAntGameList = () => {
  const games: Game[] = [
    {
      id: 'BlackAnts',
      gameSite: GameSite.BO,
      gameType: 'bo',
      name: 'Black Ants',
      imgUrl: 'blackants.jpg',
      launchCode: null,
      betCode: null,
    },
  ];
  return {
    games,
  };
};

export default useBlankAntGameList;
