import { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import type { Game } from '../api/gameLaunch';

const useSimplePlayGameList = () => {
  const games: Game[] = [
    {
      id: 'SimpleplaySongkranParty_EG_SLOT_A054',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pesta Songkran',
      imgUrl: 'simpleplay/en/EG-SLOT-A054.png',
      launchCode: 'EG-SLOT-A054',
      betCode: 'EG-SLOT-A054',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayBunnyBeauty_EG_SLOT_A053',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'B\u00e9 th\u1ecf ng\u1ecdc',
      imgUrl: 'simpleplay/en/EG-SLOT-A053.png',
      launchCode: 'EG-SLOT-A053',
      betCode: 'EG-SLOT-A053',
      rtp: 0.9609,
    },
    {
      id: 'SimpleplayWorldCupNight_EG_SLOT_A052',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Malam Piala Dunia',
      imgUrl: 'simpleplay/en/EG-SLOT-A052.png',
      launchCode: 'EG-SLOT-A052',
      betCode: 'EG-SLOT-A052',
      rtp: 0.9613,
    },
    {
      id: 'SimpleplayGoldenLegacy_EG_SLOT_A051',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Warisan Emas',
      imgUrl: 'simpleplay/en/EG-SLOT-A051.png',
      launchCode: 'EG-SLOT-A051',
      betCode: 'EG-SLOT-A051',
      rtp: 0.9424,
    },
    {
      id: 'SimpleplayGuardianofFlame_EG_SLOT_A050',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Guardian of Flame',
      imgUrl: 'simpleplay/en/EG-SLOT-A050.png',
      launchCode: 'EG-SLOT-A050',
      betCode: 'EG-SLOT-A050',
      rtp: 0.9619,
    },
    {
      id: 'SimpleplayCandyWitch_EG_SLOT_A048',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Penyihir Permen',
      imgUrl: 'simpleplay/en/EG-SLOT-A048.png',
      launchCode: 'EG-SLOT-A048',
      betCode: 'EG-SLOT-A048',
      rtp: 0.9619,
    },
    {
      id: 'SimpleplayMotherofDragons_EG_SLOT_A041',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Ibu Para Naga',
      imgUrl: 'simpleplay/en/EG-SLOT-A041.png',
      launchCode: 'EG-SLOT-A041',
      betCode: 'EG-SLOT-A041',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayVanessa_EG_SLOT_A046',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Vanessa',
      imgUrl: 'simpleplay/en/EG-SLOT-A046.png',
      launchCode: 'EG-SLOT-A046',
      betCode: 'EG-SLOT-A046',
      rtp: 0.9607,
    },
    {
      id: 'SimpleplayMythofPhoenix_EG_SLOT_A049',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Mitos Burung Api',
      imgUrl: 'simpleplay/en/EG-SLOT-A049.png',
      launchCode: 'EG-SLOT-A049',
      betCode: 'EG-SLOT-A049',
      rtp: 0.9524,
    },
    {
      id: 'SimpleplayChangThai_EG_SLOT_A047',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Chang Thai',
      imgUrl: 'simpleplay/en/EG-SLOT-A047.png',
      launchCode: 'EG-SLOT-A047',
      betCode: 'EG-SLOT-A047',
      rtp: 0.9524,
    },
    {
      id: 'SimpleplayShuDynasty_EG_SLOT_A042',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Dinasti Shu',
      imgUrl: 'simpleplay/en/EG-SLOT-A042.png',
      launchCode: 'EG-SLOT-A042',
      betCode: 'EG-SLOT-A042',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayThreeMusketeers_EG_SLOT_A045',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Tiga Pendekar Pedang',
      imgUrl: 'simpleplay/en/EG-SLOT-A045.png',
      launchCode: 'EG-SLOT-A045',
      betCode: 'EG-SLOT-A045',
      rtp: 0.9609,
    },
    {
      id: 'SimpleplayBomberSquad_EG_SLOT_A044',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pasukan Pengebom',
      imgUrl: 'simpleplay/en/EG-SLOT-A044.png',
      launchCode: 'EG-SLOT-A044',
      betCode: 'EG-SLOT-A044',
      rtp: 0.9642,
    },
    {
      id: 'SimpleplayMysticallamp_EG_SLOT_A043',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Lampu ajaib',
      imgUrl: 'simpleplay/en/EG-SLOT-A043.png',
      launchCode: 'EG-SLOT-A043',
      betCode: 'EG-SLOT-A043',
      rtp: 0.9609,
    },
    {
      id: 'SimpleplayCageFight_EG_SLOT_S008',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pertarungan Sangkar',
      imgUrl: 'simpleplay/en/EG-SLOT-S008.png',
      launchCode: 'EG-SLOT-S008',
      betCode: 'EG-SLOT-S008',
      rtp: 0.9607,
    },
    {
      id: 'SimpleplayDragonSlayer_EG_SLOT_A040',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pembuhun Naga',
      imgUrl: 'simpleplay/en/EG-SLOT-A040.png',
      launchCode: 'EG-SLOT-A040',
      betCode: 'EG-SLOT-A040',
      rtp: 0.9605,
    },
    {
      id: 'SimpleplayGoldofEgypt_EG_SLOT_A037',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Emas Mesir',
      imgUrl: 'simpleplay/en/EG-SLOT-A037.png',
      launchCode: 'EG-SLOT-A037',
      betCode: 'EG-SLOT-A037',
      rtp: 0.9618,
    },
    {
      id: 'SimpleplaySpartasHonor_EG_SLOT_A038',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Kebanggaan Sparta',
      imgUrl: 'simpleplay/en/EG-SLOT-A038.png',
      launchCode: 'EG-SLOT-A038',
      betCode: 'EG-SLOT-A038',
      rtp: 0.9638,
    },
    {
      id: 'SimpleplayHooHeyHow_EG_SLOT_C003',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Ikan Udang Kepiting',
      imgUrl: 'simpleplay/en/EG-SLOT-C003.png',
      launchCode: 'EG-SLOT-C003',
      betCode: 'EG-SLOT-C003',
      rtp: 0.9662,
    },
    {
      id: 'SimpleplayBigThreeDragons_EG_SLOT_C004',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Naga Tiga Besar',
      imgUrl: 'simpleplay/en/EG-SLOT-C004.png',
      launchCode: 'EG-SLOT-C004',
      betCode: 'EG-SLOT-C004',
      rtp: 0.9592,
    },
    {
      id: 'SimpleplaySixSwords_EG_SLOT_A039',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Enam Pedang',
      imgUrl: 'simpleplay/en/EG-SLOT-A039.png',
      launchCode: 'EG-SLOT-A039',
      betCode: 'EG-SLOT-A039',
      rtp: 0.9613,
    },
    {
      id: 'SimpleplayZeus_EG_SLOT_A036',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Zeus',
      imgUrl: 'simpleplay/en/EG-SLOT-A036.png',
      launchCode: 'EG-SLOT-A036',
      betCode: 'EG-SLOT-A036',
      rtp: 0.9422,
    },
    {
      id: 'SimpleplayMayasMiracle_EG_SLOT_A035',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Keajaiban Maya',
      imgUrl: 'simpleplay/en/EG-SLOT-A035.png',
      launchCode: 'EG-SLOT-A035',
      betCode: 'EG-SLOT-A035',
      rtp: 0.9529,
    },
    {
      id: 'SimpleplayLuckyClover_EG_SLOT_A034',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Daun Keberuntungan',
      imgUrl: 'simpleplay/en/EG-SLOT-A034.png',
      launchCode: 'EG-SLOT-A034',
      betCode: 'EG-SLOT-A034',
      rtp: 0.9601,
    },
    {
      id: 'SimpleplayThreeStarGod2_EG_SLOT_A030',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Dewa Tiga Bintang 2',
      imgUrl: 'simpleplay/en/EG-SLOT-A030.png',
      launchCode: 'EG-SLOT-A030',
      betCode: 'EG-SLOT-A030',
      rtp: 0.9609,
    },
    {
      id: 'SimpleplayPigsy_EG_SLOT_A033',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pigsy',
      imgUrl: 'simpleplay/en/EG-SLOT-A033.png',
      launchCode: 'EG-SLOT-A033',
      betCode: 'EG-SLOT-A033',
      rtp: 0.9605,
    },
    {
      id: 'SimpleplayCaiShenDao_EG_SLOT_A029',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Cai Shen Dao',
      imgUrl: 'simpleplay/en/EG-SLOT-A029.png',
      launchCode: 'EG-SLOT-A029',
      betCode: 'EG-SLOT-A029',
      rtp: 0.9609,
    },
    {
      id: 'SimpleplayTheMaskedPrince_EG_SLOT_A032',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pangeran Bertopeng',
      imgUrl: 'simpleplay/en/EG-SLOT-A032.png',
      launchCode: 'EG-SLOT-A032',
      betCode: 'EG-SLOT-A032',
      rtp: 0.9638,
    },
    {
      id: 'SimpleplayShanghaiGodfather_EG_SLOT_A027',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Shanghai Godfather',
      imgUrl: 'simpleplay/en/EG-SLOT-A027.png',
      launchCode: 'EG-SLOT-A027',
      betCode: 'EG-SLOT-A027',
      rtp: 0.9613,
    },
    {
      id: 'SimpleplayDoubleHappiness_EG_SLOT_A028',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Kebahagiaan Ganda',
      imgUrl: 'simpleplay/en/EG-SLOT-A028.png',
      launchCode: 'EG-SLOT-A028',
      betCode: 'EG-SLOT-A028',
      rtp: 0.9601,
    },
    {
      id: 'SimpleplayWorldCupFever_EG_SLOT_A031',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Demam Piala Dunia',
      imgUrl: 'simpleplay/en/EG-SLOT-A031.png',
      launchCode: 'EG-SLOT-A031',
      betCode: 'EG-SLOT-A031',
      rtp: 0.9629,
    },
    {
      id: 'SimpleplayDragon8_EG_SLOT_C002',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Naga 8',
      imgUrl: 'simpleplay/en/EG-SLOT-C002.png',
      launchCode: 'EG-SLOT-C002',
      betCode: 'EG-SLOT-C002',
      rtp: 0.9592,
    },
    {
      id: 'SimpleplayChildofWealth_EG_SLOT_A026',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Anak Kekayaan',
      imgUrl: 'simpleplay/en/EG-SLOT-A026.png',
      launchCode: 'EG-SLOT-A026',
      betCode: 'EG-SLOT-A026',
      rtp: 0.9616,
    },
    {
      id: 'SimpleplayFortuneLion_EG_SLOT_A025',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Singa Keberuntungan',
      imgUrl: 'simpleplay/en/EG-SLOT-A025.png',
      launchCode: 'EG-SLOT-A025',
      betCode: 'EG-SLOT-A025',
      rtp: 0.9642,
    },
    {
      id: 'SimpleplayLuckyFa_EG_SLOT_A024',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Lucky Fa',
      imgUrl: 'simpleplay/en/EG-SLOT-A024.png',
      launchCode: 'EG-SLOT-A024',
      betCode: 'EG-SLOT-A024',
      rtp: 0.9424,
    },
    {
      id: 'SimpleplaySuper7_EG_SLOT_C001',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: '7 Super',
      imgUrl: 'simpleplay/en/EG-SLOT-C001.png',
      launchCode: 'EG-SLOT-C001',
      betCode: 'EG-SLOT-C001',
      rtp: 0.9325,
    },
    {
      id: 'SimpleplayFortuneCat_EG_SLOT_A021',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Kucing Keberuntungan',
      imgUrl: 'simpleplay/en/EG-SLOT-A021.png',
      launchCode: 'EG-SLOT-A021',
      betCode: 'EG-SLOT-A021',
      rtp: 0.9629,
    },
    {
      id: 'SimpleplayFortuneCat_EG_SLOT_A022',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Mahjong Suci',
      imgUrl: 'simpleplay/en/EG-SLOT-A022.png',
      launchCode: 'EG-SLOT-A022',
      betCode: 'EG-SLOT-A022',
      rtp: 0.9529,
    },
    {
      id: 'SimpleplayProsperityTree_EG_SLOT_A023',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pohon Kesejahteraan',
      imgUrl: 'simpleplay/en/EG-SLOT-A023.png',
      launchCode: 'EG-SLOT-A023',
      betCode: 'EG-SLOT-A023',
      rtp: 0.9601,
    },
    {
      id: 'SimpleplayRedChamber_EG_SLOT_A008',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Ruang Merah',
      imgUrl: 'simpleplay/en/EG-SLOT-A008.png',
      launchCode: 'EG-SLOT-A008',
      betCode: 'EG-SLOT-A008',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayVolleyBeauties_EG_SLOT_S006',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Wanita Voli',
      imgUrl: 'simpleplay/en/EG-SLOT-S006.png',
      launchCode: 'EG-SLOT-S006',
      betCode: 'EG-SLOT-S006',
      rtp: 0.9615,
    },
    {
      id: 'SimpleplayZombieHunter_EG_SLOT_S007',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pemburu Zombi',
      imgUrl: 'simpleplay/en/EG-SLOT-S007.png',
      launchCode: 'EG-SLOT-S007',
      betCode: 'EG-SLOT-S007',
      rtp: 0.9612,
    },
    {
      id: 'SimpleplayCheungPoTsai_EG_SLOT_A018',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Cheung Po Tsai',
      imgUrl: 'simpleplay/en/EG-SLOT-A018.png',
      launchCode: 'EG-SLOT-A018',
      betCode: 'EG-SLOT-A018',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayAngelsDemons_EG_SLOT_S005',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Malaikat & Setan',
      imgUrl: 'simpleplay/en/EG-SLOT-S005.png',
      launchCode: 'EG-SLOT-S005',
      betCode: 'EG-SLOT-S005',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayGoldenChicken_EG_SLOT_A020',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Ayam Emas',
      imgUrl: 'simpleplay/en/EG-SLOT-A020.png',
      launchCode: 'EG-SLOT-A020',
      betCode: 'EG-SLOT-A020',
      rtp: 0.9601,
    },
    {
      id: 'SimpleplayJiGong_EG_SLOT_A006',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Ji Gong',
      imgUrl: 'simpleplay/en/EG-SLOT-A006.png',
      launchCode: 'EG-SLOT-A006',
      betCode: 'EG-SLOT-A006',
      rtp: 0.9587,
    },
    {
      id: 'SimpleplayTheGuard_EG_SLOT_A003',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Penjaga',
      imgUrl: 'simpleplay/en/EG-SLOT-A003.png',
      launchCode: 'EG-SLOT-A003',
      betCode: 'EG-SLOT-A003',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayFruitPoppers_EG_SLOT_A015',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Buah Meledak',
      imgUrl: 'simpleplay/en/EG-SLOT-A015.png',
      launchCode: 'EG-SLOT-A015',
      betCode: 'EG-SLOT-A015',
      rtp: 0.9587,
    },
    {
      id: 'SimpleplayDiamondCrush_EG_SLOT_A014',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Penghancur Berlian',
      imgUrl: 'simpleplay/en/EG-SLOT-A014.png',
      launchCode: 'EG-SLOT-A014',
      betCode: 'EG-SLOT-A014',
      rtp: 0.9587,
    },
    {
      id: 'SimpleplayTropicalTreasure_EG_SLOT_A016',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Harta Tropis',
      imgUrl: 'simpleplay/en/EG-SLOT-A016.png',
      launchCode: 'EG-SLOT-A016',
      betCode: 'EG-SLOT-A016',
      rtp: 0.9587,
    },
    {
      id: 'SimpleplayNorthSouthLions_EG_SLOT_A017',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Singan Utara Selatan',
      imgUrl: 'simpleplay/en/EG-SLOT-A017.png',
      launchCode: 'EG-SLOT-A017',
      betCode: 'EG-SLOT-A017',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayRedDragon_EG_SLOT_A011',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Naga Merah',
      imgUrl: 'simpleplay/en/EG-SLOT-A011.png',
      launchCode: 'EG-SLOT-A011',
      betCode: 'EG-SLOT-A011',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayFunnyFarm_EG_SLOT_A010',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Kebun Lucu',
      imgUrl: 'simpleplay/en/EG-SLOT-A010.png',
      launchCode: 'EG-SLOT-A010',
      betCode: 'EG-SLOT-A010',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayInnocentClassmates_EG_SLOT_A009',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Teman Sekelas yang Polos',
      imgUrl: 'simpleplay/en/EG-SLOT-A009.png',
      launchCode: 'EG-SLOT-A009',
      betCode: 'EG-SLOT-A009',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayDragonTiger_EG_SLOT_A004',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Naga dan Macan',
      imgUrl: 'simpleplay/en/EG-SLOT-A004.png',
      launchCode: 'EG-SLOT-A004',
      betCode: 'EG-SLOT-A004',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayFantasyGoddess_EG_SLOT_A005',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Bidadari Fantasi',
      imgUrl: 'simpleplay/en/EG-SLOT-A005.png',
      launchCode: 'EG-SLOT-A005',
      betCode: 'EG-SLOT-A005',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayBikiniChaser_EG_SLOT_A013',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pengejar Bikini',
      imgUrl: 'simpleplay/en/EG-SLOT-A013.png',
      launchCode: 'EG-SLOT-A013',
      betCode: 'EG-SLOT-A013',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayCreepyCuddlers_EG_SLOT_A012',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Pelampung Mengerikan',
      imgUrl: 'simpleplay/en/EG-SLOT-A012.png',
      launchCode: 'EG-SLOT-A012',
      betCode: 'EG-SLOT-A012',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayThreeStarGod_EG_SLOT_A002',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Dewa Tiga Bintang',
      imgUrl: 'simpleplay/en/EG-SLOT-A002.png',
      launchCode: 'EG-SLOT-A002',
      betCode: 'EG-SLOT-A002',
      rtp: 0.9604,
    },
    {
      id: 'SimpleplayNewYearRich_EG_SLOT_A001',
      gameSite: GameSite.SP,
      gameType: 'slot',
      name: 'Kekayaan Tahun Baru',
      imgUrl: 'simpleplay/en/EG-SLOT-A001.png',
      launchCode: 'EG-SLOT-A001',
      betCode: 'EG-SLOT-A001',
      rtp: 0.9587,
    },
    {
      id: 'SimpleplayPulaPuti_EG_PUPU_LOBBY',
      gameSite: GameSite.SP,
      gameType: 'casino',
      name: 'Pula-Puti',
      imgUrl: 'simpleplay/en/EG-PUPU-LOBBY.png',
      launchCode: 'EG-PUPU-LOBBY',
      betCode: 'EG-PUPU-LOBBY',
      rtp: 0.9697,
    },
    {
      id: 'SimpleplayShakeShake_EG_SKSK_LOBBY',
      gameSite: GameSite.SP,
      gameType: 'casino',
      name: 'Shake Shake',
      imgUrl: 'simpleplay/en/EG-SKSK-LOBBY.png',
      launchCode: 'EG-SKSK-LOBBY',
      betCode: 'EG-SKSK-LOBBY',
      rtp: 0.97,
    },
    {
      id: 'SimpleplayColorSicBo_EG_COSB_LOBBY',
      gameSite: GameSite.SP,
      gameType: 'casino',
      name: 'Color Sic Bo',
      imgUrl: 'simpleplay/en/EG-COSB-LOBBY.png',
      launchCode: 'EG-COSB-LOBBY',
      betCode: 'EG-COSB-LOBBY',
      rtp: 0.96,
    },
    {
      id: 'SimpleplayBaccarat_EG_BACC_LOBBY',
      gameSite: GameSite.SP,
      gameType: 'casino',
      name: 'Baccarat',
      imgUrl: 'simpleplay/en/EG-BACC-LOBBY.png',
      launchCode: 'EG-BACC-LOBBY',
      betCode: 'EG-BACC-LOBBY',
      rtp: 0.9894,
    },
  ];

  return {
    games,
  };
};

export default useSimplePlayGameList;
